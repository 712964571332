window.initAlpineElements = function() {
  var alpineElms = document.querySelectorAll('[x-data]');
  for (var i = 0; i < alpineElms.length; i++) {
    Alpine.initializeComponent(alpineElms[i]);
  }
}

window.clearEphemeralElements = function() {
  $('body.is--dirty').removeClass('is--dirty');
  $('[role="tooltip"], .dirtylisten.dirty .dirty-form-actions').remove();

  $('.dropdown-menu.show').dropdown('hide');
}

window.copyStripeLink = function() {
  var copyText = document.querySelector("#stripe-link");
  copyText.select();
  copyText.setSelectionRange(0, 99999)
  document.execCommand("copy");
  alert("Copied: " + copyText.value);
}

window.copyPaymentLink = function(paymentLink) {
  document.getElementById("payment-link").setAttribute('value', paymentLink);
  var copyText = document.querySelector("#payment-link");
  copyText.select();
  copyText.setSelectionRange(0, 99999)
  document.execCommand("copy");
  alert("Copied: " + copyText.value);
}

window.showLogoPreview = function(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $('#logo_prev').attr('src', e.target.result)
    };

    reader.readAsDataURL(input.files[0]);
  }
}


window.setActiveListGroupItem = function(selector) {
  var activeItems = document.querySelectorAll('.list-group-item.active');
  for (var i = 0; i < activeItems.length; i++) {
    activeItems[i].classList.remove('active');
  }
  if (selector && document.querySelector(selector)) {
    document.querySelector(selector).classList.add('active');
  }
}
window.modalHideAndDispose = function(modal) {
  $(modal).removeClass('show');
  $('.modal-backdrop').remove();
  $('body').removeClass('modal-open');
  $(modal).remove();
}

window.modalShow = function(modal) {
  $('.modal.show').each(function() {
    window.modalHideAndDispose(this);
  })

  setTimeout(function() {
    $(modal).modal('show');
    document.dispatchEvent(new Event('load:components'));
  }, 10)

  return modal;
}

window.addCleaveFormatters = function() {
  $('.input-price').toArray().forEach(function(field){
    new Cleave(field, {
      numeral: true,
      numeralPositiveOnly: field.dataset.onlyPositive,
      swapHiddenInput: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalScale: field.dataset.decimals
    });
  });
}

window.addModalAndShow = function(html) {
  var fragment = document.createRange().createContextualFragment(html);
  var modalsWrapper = document.getElementById('modals');

  modalsWrapper.appendChild(fragment);
  var modal = modalsWrapper.lastElementChild;

  window.initAlpineElements();
  window.addCleaveFormatters();

  return window.modalShow(modal);
}

window.bootstrapToggle = function($el, status, classShow) {
  if (status) {
    bootstrapShow($el, classShow);
  } else {
    bootstrapHide($el);
  }
}
window.bootstrapHide = function($el) {
  $el.removeClass('d-block d-table-row').addClass('d-none');
}
window.bootstrapShow = function($el, classShow) {
  $el.removeClass('d-none').addClass(classShow ? classShow : 'd-block');
}

/*!
 * Determine if an element is in the viewport
 * https://vanillajstoolkit.com/helpers/isinviewport/
 */
window.isInViewport = function (elem) {
  var distance = elem.getBoundingClientRect();
  return (
    distance.top >= 0 &&
    distance.left >= 0 &&
    distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

window.formatMoney = function(currency, amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount)
}

window.showModalPanel = function(panelsSelector, panelNameToShow) {
  var modalPanels = document.querySelectorAll(panelsSelector);
  for (var i = 0; i < modalPanels.length; i++) {
    var modalPanel = modalPanels[i];
    if (modalPanel.dataset.panel === panelNameToShow) {
      modalPanel.classList.add(modalPanel.dataset.panelClassActive);
      modalPanel.classList.remove(modalPanel.dataset.panelClassInactive);
    } else {
      modalPanel.classList.add(modalPanel.dataset.panelClassInactive);
      modalPanel.classList.remove(modalPanel.dataset.panelClassActive);
    }
  }
}

window.safeStartTurbolinksProgress = function() {
  if (!Turbolinks.supported) { return; }
  Turbolinks.controller.adapter.progressBar.setValue(0);
  Turbolinks.controller.adapter.progressBar.show();
}

window.safeStopTurbolinksProgress = function() {
  if (!Turbolinks.supported) { return; }
  Turbolinks.controller.adapter.progressBar.hide();
  Turbolinks.controller.adapter.progressBar.setValue(100);
}

window.stopLinkPropagation = function(event) {
  event.stopPropagation()
}

window.salesOrderLineItemUnitPriceEdit = function(unit_price, discount_amount, discount_type, surcharge_amount, surcharge_type) {
  return {
    unit_price: unit_price,
    discount_amount: discount_amount,
    discount_type: discount_type,
    surcharge_amount: surcharge_amount,
    surcharge_type: surcharge_type,
    showDiscountReason: function() {
      var discount = parseFloat(this.discount_amount.replace(/,/g, ''));
      if (isNaN(discount) || discount < 0) discount = 0;

      return discount > 0;
    },
    subtotal: function() {
      var price = parseFloat(this.unit_price.replace(/,/g, '')),
          discount = parseFloat(this.discount_amount.replace(/,/g, '')),
          surcharge = parseFloat(this.surcharge_amount.replace(/,/g, ''));

      if (isNaN(price) || price < 0) price = 0;
      if (isNaN(discount) || discount < 0) discount = 0;
      if (isNaN(surcharge) || surcharge < 0) surcharge = 0;

      if (discount > 0 && this.discount_type === 'percentage') {
        discount = discount / 100 * price;
      }
      if (surcharge > 0 && this.surcharge_type === 'percentage') {
        surcharge = surcharge / 100 * price;
      }

      return (price - discount + surcharge).toLocaleString('en-US', { maximumFractionDigits:2 });
    }
  };
}

window.paymentChange = function(balance_due, payment_amount) {
  return {
    balance_due: balance_due,
    payment_amount: payment_amount,
    showStoreChange: function() {
      var balance_due = parseFloat(this.balance_due.replace(/,/g, '')),
      payment_amount = parseFloat(this.payment_amount.replace(/,/g, ''));
      return payment_amount > balance_due;
    },
    changeAmount: function() {
      var balance_due = parseFloat(this.balance_due.replace(/,/g, '')),
          payment_amount = parseFloat(this.payment_amount.replace(/,/g, ''));

      if (isNaN(balance_due) || balance_due < 0) balance_due = 0;
      if (isNaN(payment_amount) || payment_amount < 0) payment_amount = 0;

      return Math.max(0, payment_amount - balance_due).toLocaleString('en-US', { maximumFractionDigits:2 });
    }
  };
}

window.selectAll = function() {
  var totals = document.querySelectorAll('.select-link');
  for (var i = 0; i < totals.length; i++) {
    span = totals[i]
    span.click();
  }
}

window.sumTotalCreditToApply = function(element, parentId) {
  var parent = element.closest(parentId)
  var items = parent.querySelectorAll('.to-sum');
  var tot=0;
  for (var i = 0; i < items.length; i++) {
    if(parseFloat(items[i].value))
            tot += parseFloat(items[i].value);
  }

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  var totals = parent.querySelectorAll('#total');
  for (var i = 0; i < totals.length; i++) {
    span = totals[i]
    span.innerText = span.textContent = formatter.format(tot);
  }

  var totals = document.querySelectorAll('#change-amount');
  for (var i = 0; i < totals.length; i++) {
    var paymentAmount = parseFloat(document.querySelector('input[name="payment[amount]"]').value)
    span = totals[i]
    span.innerText = span.textContent = paymentAmount - tot;
  }

  var diffs = parent.querySelectorAll('#diff-to-apply');
  for (var i = 0; i < diffs.length; i++) {
    span = diffs[i]
    var diff = span.dataset.total - tot;
    span.innerText = span.textContent = formatter.format(diff);
  }
}

$(document).on('turbolinks:load load:components', function () {
  $('.modal').on('hide.bs.modal', function (e) {
    const indicator = document.getElementById("modal-indicator")

    if (indicator && indicator.value=="submitted") {
      e.preventDefault();
    }
  });

  $('#new_sales_order').on('keypress', e => {
    if (e.keyCode == 13) {
      return false;
    }
  });

  window.initPhoneInputs();

  window.addEventListener('message', function(event) {
    var token = JSON.parse(event.data);
    var cardToken = document.getElementById('card-token');
    if (token.message){
      cardToken.value = token.message;
    }
  }, false);

});

function initMap() {
  // Create an info window to share between markers.
  const infoWindow = new google.maps.InfoWindow({
    content: "",
    disableAutoPan: true,
  });

  const coords = document.getElementById("default-location-address");
  const city = {
    lat: parseFloat(coords.getAttribute("data-latitude")),
    lng: parseFloat(coords.getAttribute("data-longitude"))
  };

  const map = new google.maps.Map(document.getElementById("map"), {
    center: city,
    zoom: 12
  });

  // Location Addresses
  const icon = {
    url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
    scaledSize: new google.maps.Size(60, 60)
  };
  const locationAddresses = document.querySelectorAll("li.location-address-item");
  locationAddresses.forEach(address => {
    const marker = new google.maps.Marker({
      position: {
        lat: parseFloat(address.getAttribute("data-latitude")),
        lng: parseFloat(address.getAttribute("data-longitude"))
      },
      title: address.getAttribute("data-name") + '\n' + address.getAttribute("data-address"),
      map: map
    });
    marker.setIcon(icon);

    marker.addListener("click", () => {
      infoWindow.setContent(address.getAttribute("data-name") + "</a><br><br>" +
                            address.getAttribute("data-address"));
      infoWindow.open(map, marker);
    });
  })

  // Customer addresses
  const addresses = Array.from(document.querySelectorAll("li.address-list-item"));
  const markers = addresses.map((address, i) => {
    const marker = new google.maps.Marker({
      position: {
        lat: parseFloat(address.getAttribute("data-latitude")),
        lng: parseFloat(address.getAttribute("data-longitude"))
      },
      title: address.getAttribute("data-customer-name") + '\n\n' + address.getAttribute("data-address-name") +
             '\n' + address.getAttribute("data-customer-address"),
    });

    marker.addListener("click", () => {
      infoWindow.setContent("<a href='" + address.getAttribute("data-customer-url") + "' target='_blank'>" +
                            address.getAttribute("data-customer-name") + "</a><br><br>" +
                            address.getAttribute("data-address-name") + " <br> " +

                            address.getAttribute("data-customer-address"));
      infoWindow.open(map, marker);
    });
    return marker;
  });

  // Add a marker clusterer to manage the markers.
  new markerClusterer.MarkerClusterer({ markers, map });

  //https://developers.google.com/maps/documentation/javascript/geolocation?hl=es-419
  const locationButton = document.createElement("button");
  locationButton.textContent = "Pan to Current Location";
  locationButton.classList.add("custom-map-control-button");
  map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
  locationButton.addEventListener("click", () => {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          infoWindow.setPosition(pos);
          infoWindow.setContent("Location found.");
          infoWindow.open(map);
          map.setCenter(pos);
        },
        () => {
          handleLocationError(true, infoWindow, map.getCenter());
        }
      );
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(false, infoWindow, map.getCenter());
    }
  });

  function handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
      ? "Error: The Geolocation service failed."
      : "Error: Your browser doesn't support geolocation."
    );
    infoWindow.open(map);
  }
}

window.initMap = initMap;

document.addEventListener('turbo:frame-render', async e => {
  if (['search-results-frame'].includes(e.target.id))
    history.pushState(history.state, '', await e.detail.fetchResponse.location)
})


document.addEventListener("turbo:before-fetch-response", function (e) {
  if (e.target.id == 'more-results') {
    const loadMore = document.getElementById('load-more')
    loadMore.remove();
  }
  if (e.target.id == 'search-form-tab') {
    history.pushState({ turbolinks: true, url: window.eventUrl }, '', current_url);
  }
});

window.initPhoneInputs = function() {
  const inputs = document.querySelectorAll('[name="account[phone_number]"], input.tel');
  inputs.forEach(input1 => {
    const iti = window.intlTelInput(input1, {
      preferredCountries: ['us', 'ca'],
      hiddenInput: () => ({ phone: input1.getAttribute("name"), country: "country_code" }),
      initialCountry: 'us',
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.10/build/js/utils.js",
      separateDialCode: true
    });

    const handleChange = () => {
      var invalidClass = 'invalid-msg'
      var container = input1.parentElement.parentElement;
      var elements = container.getElementsByClassName(invalidClass);

      while (elements[0]) {
        elements[0].parentNode.removeChild(elements[0]);
      }

      var elements = container.getElementsByClassName("invalid-feedback");

      while (elements[0]) {
        elements[0].parentNode.removeChild(elements[0]);
      }

      if (input1.value && !iti.isValidNumber()) {
        var error_div = document.createElement('div'); // is a node
        error_div.className = invalidClass;
        error_div.innerHTML = 'Phone number is invalid';
        input1.parentElement.parentElement.appendChild(error_div);
      }
    };

    // listen to "keyup", but also "change" to update when the user selects a country
    input1.addEventListener('paste', handleChange);
    input1.addEventListener('change', handleChange);
    input1.addEventListener('keyup', handleChange);
  });
}
