import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['quantityInput', 'minusButton', 'plusButton','adjustmentInput']

  connect() {
    this.submitTimer = null;
    this.minQuantity = parseInt(this.minusButtonTarget.dataset.minQuantity, 10) || 0;
    this.quantityInputTarget.addEventListener('input', this.validateInput.bind(this));
    this.adjustmentInputTarget.addEventListener('input', this.debounceUpdateQuantityFromAdjustment.bind(this));
  }

  debounceUpdateQuantityFromAdjustment() {
    // Clear the previous timeout to debounce the request
    clearTimeout(this.debounceTimer);
    // Adjust delay as needed (300ms here)
    this.debounceTimer = setTimeout(() => this.updateQuantityFromAdjustment(), 1000);
  }

  // Debounce the input validation to prevent immediate server hits
  debounceValidateInput(event) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => this.validateInput(event), 1000); // Adjust delay as needed
  }

  validateInput(event) {
    const input = event.target;
    const value = input.value;
    const filteredValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    if (value !== filteredValue) {
      input.value = filteredValue;
    }
    this.debounceSubmitForm();
  }

  decrement() {
    let quantity = parseInt(this.quantityInputTarget.value, 10) || 0;
    if (quantity > this.minQuantity) {
      quantity -= 1;
      this.updateQuantity(quantity);
    }
  }

  increment() {
    let quantity = parseInt(this.quantityInputTarget.value, 10) || 0;
    quantity += 1;
    this.updateQuantity(quantity);
  }

  updateQuantity(quantity) {
    this.quantityInputTarget.value = quantity;
    this.quantityInputTarget.dispatchEvent(new Event('change'));
    this.debounceSubmitForm();
  }

  debounceSubmitForm() {
    // Clear the previous timeout to debounce the request
    clearTimeout(this.submitTimer);
    this.submitTimer = setTimeout(() => this.submitForm(), 600); // Adjust delay as needed
  }

  submitForm() {
    const form = this.element.closest('form');
    if (form) {
      form.requestSubmit();
      this.quantityInputTarget.focus();
    }
  }

  updateQuantityFromSave(event) {
    this.submitForm();
  }

  updateQuantityFromAdjustment() {
    const adjustmentQuantity = parseInt(this.adjustmentInputTarget.value, 10) || 0;
    const currentQuantity = parseInt(this.quantityInputTarget.value, 10) || 0;

    // Calculate new quantity
    const newQuantity = currentQuantity + adjustmentQuantity;
    this.quantityInputTarget.value = newQuantity
  }
}
